<template>
  <section class="user-guide-eight-step">
    <div
      class="user-guide__input-wrapper"
    >
      <input
        class="user-guide__input"
        id="product-price"
        type="text"
        :placeholder="$translate('user_guide.step_8.store_name')"
        :value="update_store_name"
        @input="update_store_name = $event.target.value"
      />
    </div>
    <div
      class="user-guide__input-wrapper"
    >
      <md-field class="user-guide-eight-step__language-selector">
        <label for="languages">{{ $translate("user_guide.step_8.store_languages") }}</label>
        <md-select v-model="update_store_languages" name="languages" id="languages" multiple>
          <md-option
            v-for="language in languages"
            :key="language"
            :value="language"
            class="user-guide-eight-step__language"
          >
            {{ $translate(`languages.${language}`) }}
            <img
              :data-cy="`${language}`"
              :src="`/static/icons/countries/${language}.svg`"
              :alt="`${language} flag`"
            />
          </md-option>
        </md-select>
      </md-field>
    </div>
  </section>
</template>

<script>
import { languages } from "../../../../Shared/constants/other"

export default {
  props: {
    store_data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      languages
    }
  },
  computed: {
    update_store_name: {
      get() {
        return this.store_data.store_name
      },
      set(value) {
        this.$emit("update_value", value, "store_name")
      }
    },
    update_store_languages: {
      get() {
        return this.store_data.store_languages
      },
      set(value) {
        this.$emit("update_value", value, "store_languages")
      }
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-eight-step__language {
    img {
      width: 30px;
      max-height: 30px;
      object-fit: contain;
    }

    .md-list-item-text {
      flex-direction: row-reverse;
      justify-content: flex-end;
      column-gap: $half-default-size;
      align-items: center;
    }


    &-selector.md-field {
      .md-theme-default:after {
        background-color: $pure-black;
      }

      &:after {
        bottom: -5px;
        background-color: $pure-black !important;
      }

      &:before {
        opacity: 0 !important;
      }

      label {
        font-size: $font-size--large;
        color: $grey !important;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }

      &.md-has-value label {
        pointer-events: auto;
        top: 0;
        left: 0;
        transform: translateX(0);
        color: $black--light !important;
        font-size: 12px;
      }
    }
  }
</style>
